<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="viewQuotation" backdrop style="direction:ltr" width="80rem" right :title="lang.add_pay_type" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.view_quotation}}</span>
                    </div>
                    <div @click="hide" id="closeaddpayment" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="m-2" style="direction:rtl">
                    
                    <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                        <v-col cols="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`" v-if="$store.state.licenseType.activemenu != 'smallif' || $store.state.group_id != 3">
                            <div class="tobbuttom backBlack" style="padding:1px;width:100%;text-align:center;">
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:darkblue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" v-b-toggle.add_quotation @click="updateMe()">{{lang.update}}</b-button> 
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:darkblue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" @click="printPDF()">{{lang.printQuot}}</b-button> 
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:blue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" @click="printQuot()">{{lang.view_quot_pdf}}</b-button> 
                                <b-button style="border:2px solid #fff !important;font-size:1.1rem !important;background:green !important;;width:120px;" @click="doAddInv()" v-if="quot.status == 1" class="mytab2 btn btn-sm btn-primary m-2" v-b-toggle.add_invoice>{{lang.convert_to_invoice}}</b-button> 
                                <!-- <b-button style="border:2px solid #fff !important;font-size:1.1rem !important;background:dargreen !important;;width:120px;" @click="doAddInv()" v-if="quot.status == 1" class="mytab2 btn btn-sm btn-primary m-2" v-b-toggle.add_invoice>{{lang.add_contract}}</b-button>  -->
                                <b-button style="border:2px solid #fff !important;font-size:1.1rem !important;background:red !important;width:120px;" class="mytab2 btn btn-sm btn-danger m-2" v-if="quot.status == 1" @click="updateQuot(3)" v-b-toggle.returnInvoice>{{lang.cancel_quto}}</b-button> 
                                <img :src="`../../whatsappicon.png`" v-b-toggle.send_client_whats  style="border:2px solid #fff !important;font-size:1.1rem !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />
                            </div>
                        </v-col>
                        <v-col cols="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`" v-if="$store.state.licenseType.activemenu == 'smallif' && $store.state.group_id == 3">
                            <div class="tobbuttom backBlack" style="padding:1px;width:100%;text-align:center;">
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:darkblue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" v-b-toggle.add_quotation @click="updateMe()">{{lang.update}}</b-button> 
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:darkblue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" @click="printPDF()">{{lang.printQuot}}</b-button> 
                                <b-button class="mytab1 btn btn-sm btn-success m-2" style="background:blue !important;border:2px solid #fff !important;font-size:1.1rem !important;width:130px;" @click="printQuot()">{{lang.view_quot_pdf}}</b-button> 
                                <b-button style="border:2px solid #fff !important;font-size:1.1rem !important;background:red !important;width:120px;" class="mytab2 btn btn-sm btn-danger m-2" v-if="quot.status == 1" @click="updateQuot(3)" v-b-toggle.returnInvoice>{{lang.cancel_quto}}</b-button> 
                                <img :src="`../../whatsappicon.png`" v-b-toggle.send_client_whats  style="border:2px solid #fff !important;font-size:1.1rem !important;width:120px;margin-inline-start: 10px;height: 38px;border-radius: 5px;cursor:pointer" @click="openWhatsAPP()" />
                            </div>
                        </v-col>
                        <b-table-simple class="m-2" style="width:98%;margin:auto">
                            <tbody>
                                <tr>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.quotnumber}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.quot_number }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.quot_date}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot._quot_date }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.bid_number}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.bid_number }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.status}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot._status }}</td>
                                </tr>
                                <tr>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.mobile}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.mobile }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.customer_name}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.full_name }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.company_name}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.company_name }}{{ quot.entity_name }}</td>
                                    <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.branch_name}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ quot.branch_name }}</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                                <tr v-for="(item,index) in ecars" :key="index">
                                    <th class="backBlack" style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.plate_number}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ item.plate_number }}</td>
                                    <th class="backBlack" style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.model}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ item.model }}</td>
                                    <th class="backBlack" style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.car_color}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ item.color }}</td>
                                    <th class="backBlack" style="border:1px solid #555;text-align:center;background:#000;color:#FFF;border-bottom:1px solid #fff !important">{{lang.made_year}}</th>
                                    <td style="border:1px solid #555;text-align:center;">{{ item.made_year }}</td>
                                </tr>
                            </tfoot>
                        </b-table-simple>
                    </v-row>
                    <v-row style="margin:auto;width:100%">
                        <h6>{{lang.quotation_details}}</h6>
                        <b-table-simple class="m-2" style="width:98%;margin:auto">
                            <thead>
                                <tr>
                                    <th class="backBlack" style="text-align:center;" v-if="$store.state.licenseType.showCarPlate">{{lang.plate_number}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.item_code}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.description}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.qtty}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.item_price}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.discount}}</th>
                                    <th class="backGreen" style="text-align:center;">{{lang.total}}</th>
                                    <th class="backRed" style="text-align:center;">{{lang.vat}} 15%</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.ftotal}}</th>
                                    <th class="backBlack" style="text-align:center;">{{lang.action}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in quot.rows" :key="index">
                                    <td style="text-align:center;width:120px;" v-if="$store.state.licenseType.showCarPlate">{{item.plate_number}}</td>
                                    <td style="text-align:center;">{{item.itemcode}}</td>
                                    <td style="text-align:center;">{{item.item_name}}</td>
                                    <td style="text-align:center;">{{item.qty}}</td>
                                    <td style="text-align:center;">{{item.item_price}}</td>
                                    <td style="text-align:center;">{{item.discount}}</td>
                                    <td style="text-align:center;">{{item.total}}</td>
                                    <td style="text-align:center;">{{item.vat}}</td>
                                    <td style="text-align:center;">{{item.ftotal}}</td>
                                    <td style="text-align:center;">
                                        <b-button class="btn-sm btn-danger" style="width:70px;height:35px;" @click="delet(item.id)">{{lang.delete}}</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-if="$store.state.licenseType.showCarPlate">
                                        <b-input-group>
                                            <b-form-select class="selborder" v-model="newrow.plate_number" :options="carsinfo" />
                                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                <i class="fas fa-arrow-down"></i>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.itemcode"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <b-form-input v-model="newrow.item_name" list="items-list" @change="changeMe()"></b-form-input>
                                        <datalist id="items-list">
                                            <option v-for="(item,index) in itemsworkdes" :key="index">{{item.item_code}} -||- {{ item[`item_name${$parent.lang.langname}`] }}</option>
                                        </datalist>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.qty"
                                            @change="calckIt()"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.item_price"
                                            @change="calckIt()"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.discount"
                                            @change="calckIt()"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.total"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.vat"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.ftotal"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <b-button type="button" style="width:70px;height:35px" variant="success" class="btn-sm" @click="addNewRow()">{{lang.add}}</b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                        <b-table-simple :style="`width:400px;margin-`+lang.algin+`:17px;margin-`+lang.lalgin+`:auto;`">
                            <thead>
                                <tr>
                                    <th :class="`text-`+lang.lalgin+` backBlack`">{{lang.total_exclusive_vat}}</th>
                                    <td style="width:25%;border:1px solid #ccc;text-align:center;">{{fulltotals.total}}</td>
                                </tr>
                                <tr>
                                    <th :class="`text-`+lang.lalgin+` backBlack`">{{lang.vat}} 15%</th>
                                    <td style="width:25%;border:1px solid #ccc;text-align:center;">{{fulltotals.vat}}</td>
                                </tr>
                                <tr>
                                    <th :class="`text-`+lang.lalgin+` backBlack`">{{lang.ftotal}}</th>
                                    <td style="width:25%;border:1px solid #ccc;text-align:center;">{{fulltotals.ftotal}}</td>
                                </tr>
                            </thead>
                        </b-table-simple>
                    </v-row>
                    <div class="myRof" v-if="$store.state.licenseType.showSketch">
                        <div style="width:20%" v-for="(item,index) in quot.images" :key="index">
                            <div class="backBlack text-center">{{ item.plate }}</div>
                            <div>
                                <img :src="`${item.image}`" style="width:100%" />
                            </div>
                        </div>
                    </div>
                    <addInvoice ref="addInvo" />
                    <sendClientWhats ref="sendClientWhats" />
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import addInvoice from '@/components/addInvoiceQu.vue'
import sendClientWhats from '@/components/sendClientWhats.vue';
export default{
    components: {
        addInvoice,sendClientWhats
    },
    data() {
        return {
            showme: false,
            quotid: 0,
            quot: {
                status: 0,
                rows: [],
                images: []
            },
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                discount: 0
            },
            carsinfo: [],
            itemsworkdes: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        ldir: function(){
            return this.lang.ldir;
        },
        lalgin: function(){
            return this.lang.lalgin;
        },
        items: function(){
            return {
                text: this.lang.quotations,
                disabled: true,
                href: '/view-quot',
            }
        },
        ecars: function(){
            // // console.log("this is cars",this. quot.cars);
            return this.quot.cars;
        },
        fulltotals: function(){
            let t = {
                total: 0,
                ftotal: 0,
                vat: 0
            }
            
            for(let i=0;i<this.quot.rows.length;i++){
                t.total = this.$RoundNums(parseFloat(this.quot.rows[i].total) + parseFloat(t.total));
                t.ftotal = this.$RoundNums(parseFloat(this.quot.rows[i].ftotal) + parseFloat(t.ftotal));
                t.vat = this.$RoundNums(parseFloat(this.quot.rows[i].vat) + parseFloat(t.vat));
            }
            return t;
        }
    },
    methods: {
        updateMe(){
            if(this.quotid == 0) return false;
            // console.log(this.$parent.$refs.addQuot);
            this.$parent.$refs.addQuot.getQuotation(this.quotid)
        },
        changeMe(){
            // console.log(this.newrow)
            const itemn = this.newrow.item_name.split(" -||- ");
            if(itemn.length > 1){
                this.newrow.itemcode = itemn[0];
                this.getItem();
            }
        },
        getItem(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                if(resposne.data.results.data.length != 0){
                    this.newrow = {
                        itemcode: resposne.data.results.data[0].item_code,
                        description: resposne.data.results.data[0].description,
                        item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                        qty: 1,
                        item_price: resposne.data.results.data[0].default_price,
                        total: 0,
                        vat: 0,
                        ftotal: 0,
                        plate_number: '',
                        discount: resposne.data.results.data[0].discount
                    }
                    this.calcKit()
                }
            });
        },
        calckIt(index){
            //const row = this.quot.rows[index];
            const pr = parseFloat(this.newrow.item_price) - parseFloat(this.newrow.discount)
            let t = parseFloat(this.newrow.qty) * parseFloat(pr);
            const trs = this.$calcVat(t,this.quot.vtype);
            this.newrow.total = this.$RoundNums(trs.tot,2);
            this.newrow.vat = this.$RoundNums(trs.vat,2);
            this.newrow.ftotal = this.$RoundNums(trs.ftot,2);
        },
        openWhatsAPP2(){
            const mobile = this.quot.mobile.substring(1);

            window.open("https://wa.me/966"+mobile,"_blank");
        },
        openWhatsAPP(){
            this.$refs.sendClientWhats.number_list = this.customer.mobile
            this.$refs.sendClientWhats.getOldMessages();
        },
        updateQuot(status){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "updateQuot"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.quotid);
            post.append("data[status]",status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 
                // // console.log(resposne.data)
                this.$parent.getQuotations()
            })            
        },
        async doAddInv() {
            this.$refs.addInvo.quotID = this.quot.id;
            this.$refs.addInvo.customer.mobile = this.quot.mobile;
            await this.$refs.addInvo.getName();
            this.$refs.addInvo.changeCusType(parseInt(this.quot.customer_type) - 1);
            this.$refs.addInvo.tbrows = this.quot.rows;
            this.$refs.addInvo.changeCoData({
                address: this.quot.address,
                branch_name: this.quot.branch_name,
                company_name: this.quot.company_name,
                entity_name: this.quot.entity_name,
                vatid: this.quot.vatid,
                crt_number: this.quot.crt_number,
            });
            this.$refs.addInvo.cars = this.quot.cars;
            this.$refs.addInvo.ecars = this.quot.cars;
            this.$refs.addInvo.calcAll();
            this.$refs.addInvo.company.cctype = this.quot.customer_type;
        },
        printQuot(){
            window.open('../../api/print.php?quotid='+this.quotid,'_blank');
        },
        delet(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "deleteQuotationRows");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[quotid]',this.quotid)
            post.append('data[id]',id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // console.log(response.data)
                this.getQuotations()
            })
        },
        addNewRow(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "updateQuotationRows");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.quotid)
            post.append('data[newrow][plate_number]',this.newrow.plate_number)
            post.append('data[newrow][itemcode]',this.newrow.itemcode)
            post.append('data[newrow][item_name]',this.newrow.item_name)
            post.append('data[newrow][qty]',this.newrow.qty)
            post.append('data[newrow][item_price]',this.newrow.item_price);
            post.append('data[newrow][total]',this.newrow.total);
            post.append('data[newrow][vat]',this.newrow.vat);
            post.append('data[newrow][ftotal]',this.newrow.ftotal);
            post.append('data[newrow][discount]',this.newrow.discount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then(() => {
                this.getQuotations()
            })
        },
        getQuotations() {
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type', "getQuotations");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.quotid)
            post.append('data[status]',0)
            // // console.log(this.quot);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log("this is the quotation ",res);
                this.quot = res.results.data[0];
                this.carsinfo = []
                console.log("this.quot",this.quot)
                for(let i=0;i<this.quot.cars.length;i++){
                    this.carsinfo.push({text: this.quot.cars[i].plate_number,value: this.quot.cars[i].plate_number})
                    this.newrow.plate_number = this.quot.cars[0].plate_number
                }
                this.newrow.itemcode = ''
                this.newrow.item_name = ''
                this.newrow.qty = 1
                this.newrow.item_price = 0
                this.newrow.discount = 0
                this.calckIt()
            })

        },
        getInvSettings() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.itemsworkdes = [];
                            // this.allProducts = res.results.data;
                            this.itemsworkdes = res.results.data.products;
                            
                            // for(let i= 0; i < res.results.data.products.length; i++){
                            //    this.itemsworkdes.push(res.results.products[i].name);
                            // }
                        }
                    }
                }
            )
        },
        printPDF ()
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../../api/print.php?quotid='+this.quotid;
        },
    },
    created(){
        this.getInvSettings();
    }
}
</script>
<style>
.myRof{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items:center;
    align-items: center;
}
</style>